import '@happylife-a/polyfill';

import React from 'react';
import webApp from '@happylife-a/web-app';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';

ReactDOM.createRoot(document.getElementById('root')).render(
  <App RouterComponent={webApp.router.BrowserRouter} />
);
