import './configureBranding'; // eslint-disable-line import/order

import React from 'react';
import { translations, locales } from '@happylife-a/localization';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { ChakraProvider } from '@chakra-ui/react';
import { ParallaxProvider } from '~components/Parallax';
import modals from './modals';
import guestRoutes from './routes/guest';
import privateRoutes from './routes/private';
import publicRoutes from './routes/public';

const routeList = webApp.routing.buildMultiLangRoutes(locales, {
  guest: guestRoutes,
  private: privateRoutes,
  public: publicRoutes,
});

export default function App({ RouterComponent, ...props }) {
  return (
    <ParallaxProvider>
      <webCore.providers.HappyLifeCoreProvider
        fileStorage={{
          bucket: import.meta.env.VITE_AWS_S3_BUCKET,
          region: import.meta.env.VITE_AWS_S3_REGION,
          domain: import.meta.env.VITE_AWS_S3_DOMAIN,
        }}
        localStorage={{
          encryptable: false, // @TODO: enable for production mode only
          secretKey: import.meta.env.VITE_SECURE_STORAGE_SECRET,
        }}
        errorComponent={(errorProps) => (
          <webApp.UiProvider provider={ChakraProvider}>
            <webApp.screens.Whoops {...errorProps} />
          </webApp.UiProvider>
        )}
        allowGuestUser={false}
      >
        <webApp.UiProvider provider={ChakraProvider}>
          <webApp.providers.HappyLifeWebProvider
            modals={modals}
            translations={translations}
            htmlColor={{
              start: webApp.theme.colors.primary[800],
              end: webApp.theme.colors.primary[100],
            }}
            googleApi={{ key: import.meta.env.VITE_GOOGLE_API_KEY }}
            sentry={{
              dsn: import.meta.env.VITE_SENTRY_DSN,
              environment: 'Corporate',
            }}
          >
            <webCore.contexts.FavoriteProvider>
              <webCore.contexts.ShoppingCartProvider>
                <RouterComponent {...props}>
                  <webApp.contexts.ModalProvider.ModalPortal />
                  <webApp.components.ScrollToTop />

                  <webApp.router.Routes>
                    {routeList.map((route, index) => (
                      <webApp.router.Route
                        key={`AppRouteItem-${route.accessLevel}-${index}-${route.pathname}`}
                        path={route.pathname}
                        element={
                          <webApp.routing.AppRouteItem
                            {...route}
                            loginUrl="/"
                          />
                        }
                      />
                    ))}
                  </webApp.router.Routes>
                </RouterComponent>
              </webCore.contexts.ShoppingCartProvider>
            </webCore.contexts.FavoriteProvider>
          </webApp.providers.HappyLifeWebProvider>
        </webApp.UiProvider>
      </webCore.providers.HappyLifeCoreProvider>
    </ParallaxProvider>
  );
}
